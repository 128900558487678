import {
  Button,
  Text,
  Flex,
  Image,
  Modal,
  ModalFooter,
  useDisclosure,
  HeadingH2,
  Collapse,
  useBreakpoint,
  ModalBody,
  ModalHeader,
} from '@hausgold/designsystem';
import { useEffect, useState } from 'react';
import { ImageResource, WebAppManifest } from 'web-app-manifest';
import { useTranslation } from 'react-i18next';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import useTracking from 'app-utils/hooks/useTracking';
import { isIOS, isSafari, isFirefox, isMac, isAndroid } from 'app-utils/isEnv';
import { PWA_STORAGE_KEY } from 'app-utils/constants/storageKeys';

export interface PWAPromptProps {
  /**
   * If true, the Component is allowed to show its modal.
   * @default false
   */
  isActive?: boolean;
}

type UserChoice = Promise<{
  outcome: 'accepted' | 'dismissed';
  platform: string;
}>;

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: UserChoice;

  prompt(): Promise<UserChoice>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

interface ScreenshotObject extends ImageResource {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  form_factor: 'narrow' | 'wide';
  label: string;
}

/**
 * Handles the PWA install prompt.
 * Intercepts browser based pwa pop up and delays it until user clicks on our browser related modal.
 */
const PWAPrompt = ({ isActive = false }: PWAPromptProps) => {
  const { t } = useTranslation(['resource', 'common']);
  const isBiggerThanMD = useBreakpoint('md', '>');
  const [showDetails, setShowDetails] = useState(false);
  const [showInstallManual, setShowInstallManual] = useState(false);
  const [trackedMore, setTrackedMore] = useState(false);
  const [trackedInstallManual, setTrackedInstallManual] = useState(false);
  const [manifest, setManifest] = useState<WebAppManifest | null>(null);
  /*
   * Chrome / Edge / Samsung Internet event which offers native browser pop up for available pwa
   * https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent#browser_compatibility
   */
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const pwaModal = useDisclosure({ id: 'PWA' });
  const {
    isOpen,
    onClose: onCloseModal,
    onOpen: onOpenModal,
    getDisclosureProps,
  } = pwaModal;
  const { isPWA } = usePWADisplayMode();
  const isIOSDevice = isIOS();
  const isFirefoxOnAndroid = isFirefox() && isAndroid();
  const isSafariOnMac = isSafari() && isMac();
  const needManualInstallation =
    isIOSDevice || isFirefoxOnAndroid || isSafariOnMac;
  const customHandlingInformation =
    (isFirefoxOnAndroid && 'Firefox on Android') ||
    (isSafariOnMac && 'Safari on Mac') ||
    (isIOSDevice && 'iOS device') ||
    'pwa available';
  const { segmentTrack } = useTracking();

  // Open custom prompt aka our modal if the browser supports pwa (sends `beforeinstallprompt` event).
  const handleCustomPrompt = (event: BeforeInstallPromptEvent) => {
    // Prevents the default mini-info bar (browser prompt) from appearing on mobile.
    event.preventDefault();

    // Do nothing than intercept if the user already saw and reacted to the pwa prompt.
    if (localStorage.getItem(PWA_STORAGE_KEY)) {
      return;
    }

    /*
     * Save the event to open the browser prompt later.
     */
    setDeferredPrompt(event);
    // Remove the listener as it is not needed anymore
    window.removeEventListener('beforeinstallprompt', handleCustomPrompt);
  };

  // The browser own (aka system) prompt. If supported get user decision about pwa.
  const handleOpenBrowserPrompt = async () => {
    onCloseModal();

    segmentTrack('PWA Modal Install Clicked', {
      group: 'pwa',
      label: t('common:install'),
      type: 'clicked_pwa_install',
    });
    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    // The deferredPrompt can only be used once.
    setDeferredPrompt(null);

    /*
     * Act on the user's choice
     */
    if (outcome === 'accepted') {
      segmentTrack('PWA System Prompt Install Clicked', {
        group: 'pwa',
        label: t('common:install'),
        type: 'clicked_pwa_install',
      });
    } else if (outcome === 'dismissed') {
      localStorage.setItem(PWA_STORAGE_KEY, 'true');

      segmentTrack('PWA System Prompt Reject Clicked', {
        group: 'pwa',
        label: t('common:reject'),
        type: 'clicked_pwa_reject',
      });
    }
  };

  // Get our manifest to use information from.
  useEffect(() => {
    fetch('/site.webmanifest')
      .then((res) => res?.json())
      .then((newManifest) => {
        setManifest(newManifest);
      });
  }, []);

  // Adds event listener to intercept (some) browser's own pop up for pwa availability.
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleCustomPrompt, {
      passive: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Opens the modal if
   * - the manifest is loaded,
   * - the pwa request was not denied already
   * - and either the `deferredPrompt` is ready or a special browser/device is used where `deferredPrompt` does not exist.
   */
  useEffect(() => {
    if (
      manifest &&
      !localStorage.getItem(PWA_STORAGE_KEY) &&
      (deferredPrompt || needManualInstallation)
    ) {
      segmentTrack('PWA Modal Opened', {
        group: 'pwa',
        type: 'opened_pwa_modal',
        customHandling: customHandlingInformation,
      });

      onOpenModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifest, deferredPrompt, needManualInstallation]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
    setShowInstallManual(false);

    // Track only once.
    if (!trackedMore) {
      segmentTrack('PWA Modal More Clicked', {
        group: 'pwa',
        label: t('common:more'),
        type: 'clicked_pwa_more',
        customHandling: customHandlingInformation,
      });
      setTrackedMore(true);
    }
  };

  const handleToggleInstallManual = () => {
    setShowInstallManual(!showInstallManual);
    setShowDetails(false);

    // Track only once.
    if (!trackedInstallManual) {
      segmentTrack('PWA Modal install manual Clicked', {
        group: 'pwa',
        label: t('pwa.addToScreen'),
        type: 'clicked_pwa_install_manual',
        customHandling: customHandlingInformation,
      });
      setTrackedInstallManual(true);
    }
  };

  const handleCloseModal = () => {
    // Prevent modal from showing up again.
    localStorage.setItem(PWA_STORAGE_KEY, 'true');

    /*
     * Prevent tracking of "user closed the modal" on some devices/browsers as the user must click on it (there is no install button for).
     * Track only once.
     */
    if (!needManualInstallation) {
      segmentTrack('PWA Modal Reject Clicked', {
        group: 'pwa',
        label: t('common:reject'),
        type: 'clicked_pwa_reject',
        customHandling: customHandlingInformation,
      });
    }

    onCloseModal();
  };

  /*
   * Prevent rendering modal if
   * - the manifest is missing
   * - App state is not ready like user did not log in yet
   * - the pwa is already in use
   * - the modal is not open
   */
  if (!manifest || !isActive || isPWA || !isOpen) return null;

  /*
   * Our custom modal to inform about pwa and how to configure/use it.
   * Note: It is only shown if `isOpen` is true.
   */
  return (
    <Modal
      isOpen={isOpen}
      {...getDisclosureProps()}
      onClose={handleCloseModal}
      closeOnOverlayClick={false}
      // Moves the modal from center to top-right corner on bigger screens.
      contentProps={{
        top: '0px',
        right: isBiggerThanMD ? '1rem' : undefined,
        position: 'absolute',
        // Let the modal looks more vertical than horizontal on bigger screens.
        maxWidth: isBiggerThanMD ? '32rem' : '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <ModalHeader>
        <Flex alignItems="center" justifyContent="center" mr={6}>
          {manifest.icons?.[0] && (
            <Image
              src={manifest.icons[0].src}
              alt="Logo Hausgold"
              boxSize={10}
            />
          )}
          <HeadingH2 mb={0} ml={4} width="100%">
            {manifest.name}
          </HeadingH2>
        </Flex>
      </ModalHeader>

      <ModalBody>
        <Text>{t('pwa.subTitle')}</Text>
        <Text>{manifest.description}</Text>

        {manifest.screenshots?.length && (
          <Collapse in={showDetails}>
            <Flex mb={4} justifyContent="space-between">
              {(manifest.screenshots as Array<ScreenshotObject>)
                .filter(
                  ({ form_factor: formFactor }) =>
                    (isBiggerThanMD && formFactor === 'wide') ||
                    (!isBiggerThanMD && formFactor === 'narrow')
                )
                .map((screenshot, index, allScreenshots) => (
                  <Image
                    key={screenshot.src}
                    src={screenshot.src}
                    alt={screenshot.label}
                    maxWidth={`${95 / allScreenshots.length}%`}
                  />
                ))}
            </Flex>
          </Collapse>
        )}
        <Collapse in={showInstallManual}>
          {isFirefoxOnAndroid && (
            <>
              <Text mb={2}>{t('pwa.firefoxAndroid.stepOne')}</Text>
              <Text mb={2}>{t('pwa.firefoxAndroid.stepTwo')}</Text>
              <Text mb={2}>{t('pwa.firefoxAndroid.stepThree')}</Text>
            </>
          )}
          {isSafariOnMac && (
            <>
              <Text mb={2}>{t('pwa.safariMac.requirement')}</Text>
              <Text mb={2}>{t('pwa.safariMac.stepOne')}</Text>
              <Text mb={2}>{t('pwa.safariMac.stepTwo')}</Text>
              <Text mb={2}>{t('pwa.safariMac.stepThree')}</Text>
            </>
          )}
          {isIOSDevice && (
            <>
              <Text mb={2}>{t('pwa.iOS.requirement')}</Text>
              <Text mb={2}>{t('pwa.iOS.stepOne')}</Text>
              <Text mb={2}>{t('pwa.iOS.stepTwo')}</Text>
              <Text mb={2}>{t('pwa.iOS.stepThree')}</Text>
            </>
          )}
        </Collapse>
      </ModalBody>

      <ModalFooter>
        <Button mr={4} onClick={handleToggleDetails}>
          {showDetails ? t('common:less') : t('common:more')}
        </Button>
        {needManualInstallation && (
          <Button onClick={handleToggleInstallManual}>
            {t('pwa.addToScreen')}
          </Button>
        )}
        {!needManualInstallation && (
          <Button onClick={handleOpenBrowserPrompt}>
            {t('common:install')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default PWAPrompt;
